.create-update-banner {
  .content-card {
    .ant-card-body {
      width: 100%;
      .content-banner {
        .content-left {
          cursor: pointer;
        }
        .content-right {
          padding-left: 20px;
          .ant-form-item-control-input-content {
            height: 135px;
          }
        }
      }
    }
  }
  .position-relative {
    .header-form {
      p {
        font-size: 16px;
        line-height: 140%;
        font-weight: 500;
        color: #23262f;
      }
    }
  }
}
@media screen and(max-width: 768px) {
  .create-update-banner {
    .content-card {
      .ant-card-body {
        .content-banner {
          .content-left {
          }
          .content-right {
            padding-left: 0;
          }
        }
      }
    }
  }
}
