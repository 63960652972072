.sticky-group-btn {
  position: sticky;
  bottom: 50px;
}

.h-100-input {
  .ant-form-item-control-input {
    height: 100%;
    .ant-form-item-control-input-content {
      height: 100%;
    }
  }
}

.fs-16 {
  font-size: 16px;
}

.form-customer-service {
  .ant-form-item {
    margin-bottom: 5px !important;
  }
  .input-error {
    font-size: 12px;
    color: red;
  }
}

.customer-full-name {
  color: #096dd9;
  cursor: pointer;
  &:hover {
    color: #3c97f8d0;
  }
}
