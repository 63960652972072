.store {
  .branch_name {
    font-weight: bold;
    color: #23262f;
    padding-left: 0;
    font-size: 16px;
    // &:active,
    // &:focus {
    //   // box-shadow: none;
    //   border: 1px !important;
    // }
  }
  .visibility-hidden {
    visibility: hidden;
    pointer-events: none;
  }
  .section-delivery-name {
    .ant-card-body {
      padding-top: 0 !important;
    }
    .delivery-name {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-bottom: 20px;
      .ant-row {
        margin: 0 !important;
      }
    }
  }
}
