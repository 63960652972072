.total-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;
  margin-bottom: 30px;

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .item {
    background: #fcfcfd;
    box-shadow: 0px 8px 48px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 24px;

    display: grid;
    grid-template-columns: 69px 1fr;
    gap: 16px;
    justify-content: space-between;
    align-items: center;

    .icon {
      background: red;
      border-radius: 8px;
      width: 100%;
      height: 69px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .revenue {
      background: rgba(0, 114, 248, 0.08);
    }

    .user {
      background: rgba(255, 182, 0, 0.08);
    }

    .order {
      background: rgba(104, 60, 115, 0.08);
    }

    .product {
      background: rgba(9, 181, 48, 0.08);
    }

    .content {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      gap: 8px;

      .title {
        font-size: 16px;
        color: #777e91;
        width: 100%;
        display: block;
      }

      .total {
        font-weight: 700;
        font-size: 28px;
        line-height: 140%;
        color: #23262f;
      }

      .percent {
        font-size: 16px;
        line-height: 140%;
        display: flex;
        gap: 8px;
        align-items: center;
      }

      .up {
        color: #09b530;

        path {
          fill: #09b530;
        }
      }

      .down {
        color: #f80000;

        svg {
          transform: rotate(180deg);
        }

        path {
          fill: #f80000;
        }
      }
    }
  }
}
