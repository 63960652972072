.ant-steps-item.ant-steps-item-finish.ant-steps-item-active
  + .ant-steps-item.ant-steps-item-wait
  .ant-steps-item-container {
  .ant-steps-item-icon {
    border-color: #2c3a57 !important;
  }

  .ant-steps-item-content .ant-steps-item-title {
    color: #23262f !important;
  }
}
