.section-dashboard {
  .card {
    background: #fcfcfd;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 24px;
    margin-bottom: 24px;
    height: 641.8px;

    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-weight: 700;
        font-size: 20px;
        line-height: 140%;
        color: #23262f;
      }

      .actions {
        .seeMores > a {
          font-size: 16px;
          line-height: 140%;
          color: #0072f8;
          font-weight: 500;
        }

        .ant-radio-button-wrapper {
          height: 20px;
          font-size: 12px;
          color: #777e91;
          align-items: center;
          justify-content: center;
          display: inline-flex;
          background: transparent;
          border: none;

          &:before {
            display: none;
          }
        }

        .ant-radio-button-wrapper-checked {
          background: #2c3a57;
          border-radius: 4px;
          color: #fcfcfd;
          font-weight: 400;
        }

        .ant-radio-group {
          height: 28px;
          background: #f4f5f6;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          border-radius: 4px;
          border-color: #f4f5f6;
        }
      }
    }

    .content {
      margin-top: 24px;

      .ant-table {
        box-shadow: none;
      }

      .ant-table-row {
        cursor: pointer;
      }
    }
  }
}
