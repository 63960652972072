@import '@assets/styles/color.scss';

.section-categories {
  .section-header {
    padding: 24px 0;
    display: flex;
    justify-content: flex-end;
    .ant-btn {
      font-size: 20px;
      font-weight: 700px;
      background-color: $primary-color;
      color: #fff;
      justify-content: center;
      display: flex;
      align-items: center;
      border-radius: 5px;
    }
  }

  .ant-table-tbody > tr > td {
    font-size: 16px;
    font-weight: 500;
  }

  .ant-table-tbody {
    .ant-table-row {
      .ant-table-cell {
        .anticon {
          width: 35px;
          font-size: 25px;
          cursor: pointer;
        }
        .anticon-edit {
          color: $green-color;
        }
        .anticon-delete {
          color: $primary-color;
        }
      }
    }
  }
  .container_input_header {
    width: 50% !important;

    .ant-input {
      border-right: 0;

      &:hover,
      &:focus {
        box-shadow: none;
        border: 1px solid #e6e8ec;
        border-right: 0;
      }
    }

    .ant-input-group-addon {
      background: #fff;
    }
  }
}
.row-dragging-cate {
  background: #fafafa;
  border: 1px solid #ccc;
  display: flex;

  .ant-table-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    font-size: 16px;
    font-weight: 500;

    &:first-child {
      width: 46px;
    }

    &:nth-child(2) {
      width: 5%;
    }

    &:nth-child(3) {
      width: 7%;
    }

    &:nth-child(4) {
      width: 15%;
      justify-content: flex-start;
    }

    &:nth-child(5) {
      width: calc(49% - 46px);
      justify-content: flex-start;
    }

    &:nth-child(6) {
      width: 10%;
      justify-content: center;
    }
    &:nth-child(7) {
      width: 15%;
      justify-content: center;
    }
  }
}

.row-dragging-cate td {
  padding: 16px;
}

.row-dragging-cate .drag-visible {
  visibility: visible;
}

.page-create-category {
}
