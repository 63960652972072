.row-dragging-banner {
  visibility: visible;
  width: 100%;
  .ant-card-bordered {
    display: block !important;
    .ant-form-item {
      display: grid;
      .ant-form-item-label {
        display: flex;
        justify-content: flex-start;
      }
    }
    .content-right {
      padding-left: 20px;

      .ant-row {
        .ant-col:nth-child(2) .ant-form-item-control-input-content {
          height: 135px;
        }
      }
    }
  }
}

.banner {
  .ant-form-item-explain.ant-form-item-explain-error {
    transform: translateY(13px);
  }
}
