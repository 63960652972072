.ant-pagination {
  .ant-pagination-item-active {
    background: $second-primary-color;
    border-color: $colorWhite;

    a {
      color: $colorWhite;
    }
  }

  .ant-pagination-item,
  .ant-pagination-item-link {
    border-radius: 8px;
    border: 0;
  }
}
