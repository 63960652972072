@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto/Roboto-Bold.ttf');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto/Roboto-Medium.ttf');
  font-style: normal;
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url('/fonts/Roboto/Roboto-Regular.ttf');
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

body {
  font-family: 'Roboto', -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
  // font-size: normal normal medium 18px/24px;
  font-size: 16px;
}

.fs-25 {
  font-size: 25px;
}

.fs-20 {
  font-size: 20px;
}

.fs-18 {
  font-size: 18px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.font-bold {
  font-weight: bold;
}
