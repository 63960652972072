.ant-tabs {
  .ant-tabs-nav-list {
    width: 100%;
    // border-bottom: 2px solid #7170703b;
    .ant-tabs-tab {
      padding: 12px 20px;
      font-size: 16px;
      letter-spacing: 0px;
      color: #666666;
      opacity: 1;
    }
  }
  .ant-tabs-nav {
    &::before {
      border-bottom: 1px solid #d4d4d4;
    }
  }
}
