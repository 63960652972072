.sections-notify {
  .headerNotifyList {
    padding: 20px 0;
    width: 100%;
    position: absolute;
    z-index: 999;
    top: -8px;
    margin-left: 52px;
    margin-right: 52px;
    .headerNotifyList-left {
      width: 50%;
      .checkedAll {
        justify-content: center;
        align-items: center;
        padding-right: 15px;
      }
      .btn-view-all {
        border-radius: 24px;
        margin-right: 25px;
        width: 51px;
        height: 38px;
        border: 1px solid #2c3a57;
      }
      .btn-view-Unread {
        border-radius: 24px;
        height: 38px;
        border: 1px solid #2c3a57;
        width: 105px;
      }
      .active {
        background-color: #2c3a57;
        color: #f4f5f6;
      }
    }
    .headerNotifyList-right {
      width: 50%;
      margin-left: -62px;
      .mark-all-as-read {
        color: #0072f8;
        cursor: pointer;
        margin-right: 15px;
      }
      .delete-notify {
        color: red;
        cursor: pointer;
      }
    }
  }
  .ant-table-wrapper {
    .noti-type {
      border-radius: 50%;
      height: 48px;
      width: 48px;
    }
    .confirmed {
      background-color: #683c73;
      border-radius: 50%;
      padding: 5px;
    }
    .finished {
      background-color: #09b530;
      border-radius: 50%;
      padding: 5px;
    }
    .cancelled {
      background-color: #f80000;
      border-radius: 50%;
      padding: 5px;
    }
    .seen {
      color: #8c8e92;
    }
    .ant-table-tbody {
      .ant-table-row:hover {
        td {
          background: #ebf2fd !important;
        }
      }
    }
  }
}
