.content_form {
  padding: 24px;
  background: #fcfcfd;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  margin-bottom: 32px;
  height: 100%;
  .header {
    .title {
      font-weight: 500;
      font-size: 16px;
    }
  }
  .ant-card {
    background: none;
    .ant-form-item-label {
      label {
        font-weight: normal;
      }
    }

    .ant-input {
      background: none;
    }
  }
}
